<template>
<b-card v-if="interviews" no-body class="card-company-table">
    <b-button @click="exportExcell">
        Indır
    </b-button>
    <b-table :items="interviews" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>
        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt 
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- Start Time -->
        <template #cell(startDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.startDate 
          }}</span>
        </template>

        <!-- End Time -->
        <template #cell(endDate)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.endDate
          }}</span>
        </template>

        <!-- From User -->
        <template #cell(userName)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.from.firstName+ ' ' +data.item.from.lastName
          }}</span>
        </template>

        <!-- Level -->
        <template #cell(level)="data">
            <b-badge pill :variant="getLevelColor(data.item.level)">
                {{getLevelText(data.item.level)}}
            </b-badge>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-warning" @click="editGain(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Düzenle</span>
            </b-button>
        </template>
    </b-table>

    <!--Open Add Gain-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addGainPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Görüşme Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>
            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Öğrenci" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(user) => user.id" :options="allUsers" v-model="addInterviewRequest.userId" :getOptionLabel="option => option.firstName+' '+option.lastName" />
                </b-form-group>
                <b-form-group label="Kod" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addInterviewRequest.code" />
                </b-form-group>
                <b-form-group label="Görüşme Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addInterviewRequest.name" />
                </b-form-group>
                <b-form-group label="Önem Derecesi" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(level) => level.key" :options="levels" v-model="addInterviewRequest.level" label="label" />
                </b-form-group>
                <b-form-group label="Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="addInterviewRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="addInterviewRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Görüşme Notu" label-for="name">
                    <b-form-textarea id="textarea-default" v-model="addInterviewRequest.note" rows="5" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addInterview">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>

            </b-form>
        </template>

    </b-sidebar>

    <!--Open Edit Gain-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editGainPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Kazanım Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Öğrenci" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(user) => user.id" :options="allUsers" v-model="editInterviewRequest.userId" :getOptionLabel="option => option.firstName+' '+option.lastName" />
                </b-form-group>
                <b-form-group label="Kod" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editInterviewRequest.code" />
                </b-form-group>
                <b-form-group label="Görüşme Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editInterviewRequest.name" />
                </b-form-group>
                <b-form-group label="Önem Derecesi" label-for="vue-select">
                    <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(level) => level.key" :options="levels" v-model="editInterviewRequest.level" label="label" />
                </b-form-group>
                <b-form-group label="Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="editInterviewRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="editInterviewRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                </b-form-group>
                <b-form-group label="Görüşme Notu" label-for="name"  style="height:500px">
                    <quill-editor v-model="editInterviewRequest.note" style="height:400px" />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateInterview">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>

            </b-form>
        </template>

    </b-sidebar>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import XLSX from 'xlsx';

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
export default {
    components: {
        quillEditor,
        vSelect,
        flatPickr,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'code', label: 'Görüşme Kodu' },
                { key: 'name', label: 'Görüşme Adı' },
                { key: 'userName', label: 'Görüşülecek Kullanıcı' },
                { key: 'level', label: 'Görüşme Seviyesi' },
                { key: 'startDate', label: 'Görüşme Tarihi' },
                { key: 'endDate', label: 'Görüşme Bitiş Tarihi' },
                { key: 'actions', label: 'İşlemler' },
            ],
            levels: [
                { key: 'Critical', label: 'Kritik' },
                { key: 'High', label: 'Yüksek' },
                { key: 'Middle', label: 'Orta' },
                { key: 'Low', label: 'Düşük' },
                { key: 'Information', label: 'Bilgilendirme' }
            ],
            interviews: [],
            addGainPanel: false,
            editGainPanel: false,
            addInterviewRequest: {
                userId: 0,
                code: '',
                name: '',
                level: '',
                startDate: '',
                endDate: '',
                note: ''
            },
            editInterviewRequest: {
                interviewId: '',
                userId: 0,
                code: '',
                name: '',
                level: '',
                startDate: '',
                endDate: '',
                note: ''
            },
            allUsers: []
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addGainPanel = true;
        });
    },
    created() {
        this.getData();
        this.getUserData();
    },
    methods: {
        async getData() {
            var interviews = await this.$http.get("Teacher/Interviews");
            this.interviews = interviews.data.data;
        },
        async getUserData() {
            var allUsers = await this.$http.get("Teacher/AllStudent");
            this.allUsers = allUsers.data.data;
        },
        getLevelColor(level) {
            switch (level) {
                case 'Critical':
                    return "danger"
                case 'High':
                    return "light-danger"
                case 'Middle':
                    return "light-warning"
                case 'Low':
                    return "light-info"
                case 'Information':
                    return "light-success"
            }
        },
        getLevelText(level) {
            switch (level) {
                case 'Critical':
                    return "Kritik"
                case 'High':
                    return "Yüksek"
                case 'Middle':
                    return "Orta"
                case 'Low':
                    return "Düşük"
                case 'Information':
                    return "Bilgilendirme"
            }
        },
        convertToDateTime(fullDate){
             var date=fullDate.split(' ')[0];
            var time=fullDate.split(' ')[1];
            var splitDate=date.split('-');
            //2022-05-17T22:29:47.919Z
            return `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}T${time}:00.000Z`;
       
        },
        editGain(data) {
            this.editInterviewRequest = {
                interviewId: data.id,
                userId: data.from.id,
                code: data.code,
                name: data.name,
                level: data.level,
                startDate: data.startDate,
                endDate: data.endDate,
                note: data.note
            };
            this.editGainPanel = true;
        },
        addInterview() {
            var request = this.addInterviewRequest;
            request.endDate = request.endDate != '' ? request.endDate : '01-01-0001 01:01';
            request.endDate =this.convertToDateTime(request.endDate);
            request.startDate=this.convertToDateTime(request.startDate);
            this.$http.post("Teacher/AddInterview", request).then((data) => {
                    this.getData();
                    this.addGainPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Görüşme Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateInterview() {
            var request = this.editInterviewRequest;
            request.endDate = request.endDate != '' ? request.endDate : '01-01-0001 01:01';
            request.endDate =this.convertToDateTime(request.endDate);
            request.startDate=this.convertToDateTime(request.startDate);
            this.$http.put("Teacher/UpdateInterview", request).then((data) => {
                    this.getData();
                    this.editGainPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Görüşme Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        async exportExcell(){
            var exportData =[];
            this.interviews.forEach(element=>{
                var row ={ };
                row["Görüşme Başlangıç Tarihi"] = element.startDate;
                row["Görüşme Bitiş Tarihi"] = element.endDate;
                row["Görüşme Seviyesi"] = this.getLevelText(element.level);
                row["Öğrenci Adı"] = element.from.firstName +" "+ element.from.lastName;
                row["Görüşme Adı"] = element.name;
                row["Görüşme Notu"] = element.note;
                exportData.push(row);
            });
            let data = XLSX.utils.json_to_sheet(exportData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, data, 'data');
            await XLSX.writeFile(wb, ' Rapor.xlsx');
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
